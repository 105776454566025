import * as React from "react";

export interface ILogoProps {
  src?: string;
  id: string;
  alt?: string;
}

export function Logo(props: React.PropsWithChildren<ILogoProps>) {
  const { src, children, id, alt } = props;

  return (
    <div className="logo">
      <img src={src} alt={alt} />
    </div>
  );
}
