import type { RenderableTreeNode } from "@markdoc/markdoc";
import Markdoc, { type Node } from "@markdoc/markdoc";
import _ from "lodash";
import type { IUser } from "../../../../../../../interfaces/user";
import type { AvailableColumn } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { parseLabel } from "../../../domain";
import { generateConfig } from "../../common/markdoc/domain";
import { convertKeyToMarkdocVariable } from "../../widgets/markdown/domain";

export interface IEmailWidgetTextConfig {
  content?: string;
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
}

export const getDefaultConfig = (
  conf: IEmailWidgetTextConfig,
  record: IRecord
): IEmailWidgetTextConfig => {
  return {
    content: conf.content ? conf.content : "",
    align: conf.align ? conf.align : "left",
    spacing: conf.spacing ? conf.spacing : "normal",
  };
};

export const extractVariableFromMarkdocAst = (source: string): string[] => {
  const ast = Markdoc.parse(source);
  const variables: string[] = [];
  for (const node of ast.walk()) {
    if (
      node.attributes?.content?.$$mdtype === "Variable" &&
      Array.isArray(node.attributes?.content?.path)
    ) {
      variables.push(
        (node.attributes?.content.path as Array<string>).join(".")
      );
    } else if (node.attributes?.content?.$$mdtype === "Function") {
      const v = Object.values(node.attributes?.content?.parameters).filter(
        (p) => (p as any)?.$$mdtype === "Variable"
      );
      v.forEach((a) =>
        variables.push(((a as any).path as Array<string>).join("."))
      );
    }
    // if feel like we are not accounting for variable passed in tags here --- we should add it
  }
  return variables;
};

export const getParsedDoc = (
  source: string,
  record: IRecord,
  user: IUser,
  availableColumns: AvailableColumn[],
  additionalTransform?: object
): RenderableTreeNode => {
  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});

  const transformAst = (ast: Node): Node => {
    const newAst = _.cloneDeep(ast);
    for (const node of newAst.walk()) {
      // do something with each node
      // this is where we can extract variable names and inject the columns definition for each variables
      if (node.type === "tag" && node.tag === "group") {
        const newNode = structuredClone(node);
        node.attributes = {
          ...node.attributes,
          node: JSON.stringify(newNode),
        };
        node.annotations = [
          ...node.annotations,
          { type: "attribute", name: "node", value: JSON.stringify(newNode) },
        ];
      }
    }
    return newAst;
  };

  const config = generateConfig(user, availableColumns);
  const ast = Markdoc.parse(source);
  const newAst = transformAst(ast);
  const content = Markdoc.transform(newAst, {
    ...config,
    ...additionalTransform,
    variables: formattedRecord,
  });
  return content;
};

export const getFormattedRecord = (
  columns: AvailableColumn[],
  record: IRecord
) => {
  return Object.keys(record).reduce<IRecord>((acc, v) => {
    const column = columns.find((c) => c.data.key === v);
    if (!column) {
      return acc;
    }

    const format = () => {
      if (column.type === "property" && column.data.type !== "standard") {
        const parsedValue = parseLabel(record[v] as string);
        return parsedValue.name ? parsedValue?.name : parsedValue.id;
      }
      return record[v];
    };

    return {
      ...acc,
      [v]: format(),
    };
  }, {});
};
