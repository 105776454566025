import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type { Formatter } from "../../../../../../../../../../components/chart/domain";
import { customFormatter } from "../../../../../../../../../../components/chart/utils/optionsHelper";
import type {
  IUser,
  UserLocale,
} from "../../../../../../../../../../interfaces/user";
import { type AvailableColumn } from "../../../../../../../object/domain";
import { getParsedDoc } from "../../../../../email-widgets/text/domain";
import renderTreeNodeAsText from "../../../../generated-text/renderAsText";
import { convertKeyToMarkdocVariable } from "../../../../markdown/domain";
import { nodesConfig } from "../../../../markdown/widget/markdoc-react-component/config";
import type { kpiTheme } from "../../../domain";

export interface IBarSparklineRendererProps {
  config: {
    x: {
      key: string;
    };
    y: { key: string; formatter: Formatter };
    options?: {
      highlightLabel?: string;
    };
    tooltip?: string;
  };
  data: Array<{
    [key: string]: string | number | boolean;
  }>;
  locale: UserLocale;
  theme: kpiTheme;
  user: IUser;
  availableColumns: AvailableColumn[];
}

export function BarSparklineRenderer(props: IBarSparklineRendererProps) {
  const { config, data, theme, locale, user, availableColumns } = props;

  const formattedData: Array<{ x: string; y: number }> = data.map(
    (datapoint) => {
      const x = datapoint[config.x.key]?.toString?.();
      const y = parseFloat(datapoint[config.y.key].toString()) as number;
      return { x, y };
    }
  );

  const barchartOptions: Highcharts.Options = {
    chart: {
      type: "column",
      height: 110,
      spacing: [0, 0, 0, 0],
      backgroundColor: "transparent",
      animation: false,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      categories: formattedData.map((datapoint) => datapoint.x),
      labels: {
        enabled: true,
        rotation: 0,
        useHTML: true,
        formatter: function () {
          const label = this.axis.defaultLabelFormatter.call(this);
          const labelToHighlight = config.options?.highlightLabel;
          if (labelToHighlight && label === labelToHighlight) {
            return `<div style="background: ${theme.primary};color: ${theme.background};padding: 0px 3px; border-radius:6px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${label}</div>`;
          }
          return label;
        },
        style: {
          color: theme.secondary,
          fontFamily: "Lato",
          fontSize: "11px",
        },
      },
      lineWidth: 0,
      title: {
        text: undefined,
      },
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      outside: true,
      shadow: false,
      backgroundColor: "#2E363B",
      borderRadius: 5,
      hideDelay: 0,
      pointFormatter: function () {
        const renderTooltip = () => {
          if (!config.tooltip) {
            return "";
          }
          const d = data[this.x];
          const formattedD = Object.keys(d).reduce((acc, v) => {
            return {
              ...acc,
              [convertKeyToMarkdocVariable(v)]: d[v],
            };
          }, {});
          const content = getParsedDoc(
            config.tooltip,
            formattedD,
            user,
            availableColumns,
            nodesConfig
          );

          const r = renderTreeNodeAsText(content);
          return `
          <hr style="margin-left:-8px;margin-right:-8px" />
          <p style="margin-bottom:0">${r}</p>
          `;
        };

        return `<div style="max-width:250px">
          <b>${customFormatter(this.y, locale, config.y.formatter)}</b>
         ${renderTooltip()}
        </div>
      `;
      },
      style: {
        color: "white",
        fontSize: "0.85em",
      },
    },
    plotOptions: {
      column: {
        groupPadding: 0.1,
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        animation: false,
        color: theme.secondary,
        states: {
          hover: {
            enabled: false,
          },
        },
        data: formattedData.map((datapoint) => {
          let color = theme.secondary;
          if (config.options?.highlightLabel === datapoint.x) {
            color = theme.primary;
          }
          return {
            color: color,
            y: datapoint.y,
          };
        }),
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={barchartOptions} />;
}
