import { QuestionCircleOutlined, TableOutlined } from "@ant-design/icons";
import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import type { AvailableDimension } from "../../../../../components/measures/filter-item/FilterItem";
import type {
  IObject,
  IObjectPropertyActivitiesFormatterConfig,
  IObjectPropertyFormat,
  IObjectPropertyFormats,
  IObjectPropertyPillsFormatterConfig,
  IObjectPropertyType,
  IObjectView,
} from "../../../../../interfaces/object";
import { IObjectViewView } from "../../../../../interfaces/object";
import type { DataType } from "../../../../../interfaces/transformations";
import { parseQueryBuilderSectionItemConfidProperty } from "../../../../workbench/workbench/viewer/object/tabs/query-builder/domain";

export interface AvailableProperty {
  key: string; // string.string -> what to display in the table (used in dimension)
  sortAndFilterKey: string; // if foreignKey (foreignCubeName.name) else if (primaryKey) (currentCubeName.name) else (queryKey)
  label: string;
  object?: IObject;
  description?: string;
  type: IObjectPropertyType | "primaryKey";
  domain: DataType;
  formatter?: IObjectPropertyFormats;
  formatterConfig?: string;
  hierarchyPath?: string;
}

export const renderObjectViewIcon = (
  view: IObjectView | undefined,
  style?: React.CSSProperties
) => {
  if (!view || view.type === IObjectViewView.table) {
    return <TableOutlined style={{ ...(style ?? {}) }} />;
  }
  return <QuestionCircleOutlined style={{ ...(style ?? {}) }} />;
};

export const isTimeDimension = (key: string) => {
  if (key.endsWith(".day")) return true;
  if (key.endsWith(".week")) return true;
  if (key.endsWith(".month")) return true;
  if (key.endsWith(".year")) return true;
  return false;
};

export const getDimensionPartFromTimeDimension = (key: string) => {
  return key
    .replaceAll(".day", "")
    .replaceAll(".week", "")
    .replaceAll(".month", "")
    .replaceAll(".year", "");
};

export const getTimePartFromTimeDimension = (key: string) => {
  if (key?.endsWith?.(".day")) return "day";
  if (key?.endsWith?.(".week")) return "week";
  if (key?.endsWith?.(".month")) return "month";
  if (key?.endsWith?.(".year")) return "year";
  return null;
};

export const getAvailableDimensions = (
  availableProperties: AvailableProperty[],
  options: {
    type: "display" | "sortAndFilter";
    usedInQuery?: boolean;
    query?: Query;
  }
): AvailableDimension[] => {
  return (availableProperties ?? [])
    .filter((p) => {
      if (options.usedInQuery && options.query) {
        return (options.query.dimensions ?? []).includes(p.key);
      } else {
        return true;
      }
    })
    .map((p) => {
      let key = p.key;
      let domain = p.domain;
      if (options.type === "sortAndFilter") {
        const sortAndFilterProperty = availableProperties.find(
          (ap) => ap.key === p.sortAndFilterKey
        );
        key = p.sortAndFilterKey;
        domain = sortAndFilterProperty
          ? sortAndFilterProperty.domain
          : p.domain;
      }
      return {
        key: key,
        domain: domain,
        type: "standard",
        label: p.label,
        description: p.description,
        allowTimeAgg: domain === "TIME",
      };
    }) as AvailableDimension[];
};

export const parseObjectPropertyFormatter = (
  formatter: IObjectPropertyFormats,
  formatterConfig: string
): IObjectPropertyFormat => {
  switch (formatter) {
    case "pills":
      return {
        type: "pills",
        config: JSON.parse(formatterConfig),
      } as IObjectPropertyPillsFormatterConfig;
    case "score":
      return {
        type: "score",
        config: formatterConfig ? JSON.parse(formatterConfig ?? "{}") : {},
      };
    case "activities":
      return {
        type: "activities",
        config: formatterConfig ? JSON.parse(formatterConfig) : {},
      } as IObjectPropertyActivitiesFormatterConfig;
    default:
      return null;
  }
};

export const DUMMY_TOKEN = "____wly_dummy____";

export const tableQueryManualFilterKey = "filters[0].and[0].values";

export const MANUAL_LIST_LIMIT = 101; // add one for the dummy token

export const getReferencedTableFromObject = (object: IObject): string[] => {
  const allReferencedProperties = object.queryBuilderSections.flatMap((a) =>
    a.items.flatMap((i) =>
      parseQueryBuilderSectionItemConfidProperty(i.property)
    )
  );
  return _.uniq(
    allReferencedProperties.map((rp) => {
      const [tab, prop] = rp.key.split(".");
      return tab.replaceAll("Tab_", "");
    })
  ).filter((t) => t !== object.table.id);
};
