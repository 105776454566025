import { Typography } from "antd";
import * as React from "react";

export interface IHeadingProps {
  level: number;
  id: string;
}

export function Heading(props: React.PropsWithChildren<IHeadingProps>) {
  const { level, children, id } = props;

  return (
    <Typography.Title id={id} level={level as any}>
      {children}
    </Typography.Title>
  );
}
