import { type MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";

export interface IGeneratedTextConfig extends BaseConfig {
  model: string;
  prompt: string;
  dataSheets: Array<IGeneratedTextDataSheet>;
}

export interface IGeneratedTextDataSheet {
  id: string;
  name: string;
  type: "MODEL" | "OBJECT";
  additionalFilters: IFilterEditorValue;
  from: string;
  select: string[];
  sortBy?: Array<[string, MeasureItemSortValue]>;
  limit: {
    type: "value" | "column";
    value: string;
  };
}

export const substitutionColumnPrefix = `_wly:`;
