import { Skeleton } from "antd";
import type { IRendererConfig } from "../domain";
import type { IWidgetInlineObjectiveConfig } from "./domain";
import { InlineObjectiveWidgetEditor } from "./editor/InlineObjectiveWidgetEditor";
import { InlineObjectiveWidget } from "./widget/InlineObjectiveWidgetRenderer";

export const widgetInlineObjectiveDefinition: IRendererConfig<IWidgetInlineObjectiveConfig> =
  {
    removeCard: true,
    parseConfig: (widget) => {
      const defaultValue: IWidgetInlineObjectiveConfig = {};

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IWidgetInlineObjectiveConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, form, org) => {
      return (
        <InlineObjectiveWidgetEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
        />
      );
    },
    renderComponent: (widget, object, record, conf, edit) => {
      return (
        <InlineObjectiveWidget
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          edit={edit}
        />
      );
    },
    renderLoadingComponent: (widget, conf) => (
      <Skeleton.Button
        active
        block
        size="small"
        shape="default"
        style={{ height: 75 }}
      />
    ),
  };
