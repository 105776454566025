import type { FormInstance } from "antd";
import { Form, Select, Space } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetInlineObjectiveConfig } from "../domain";

interface InlineObjectiveWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetInlineObjectiveConfig;
  form: FormInstance;
}

export function InlineObjectiveWidgetEditor(
  props: InlineObjectiveWidgetEditorProps
) {
  const { widget, object, record, conf, form } = props;

  return (
    <>
      <ChartOptionCollapse dark title="Insights Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item label="Config" name={["config", "conf"]}>
                    <Select>
                      <Select.Option value="PDL_UF">PDL uf</Select.Option>
                      <Select.Option value="DNR">DNR</Select.Option>
                      <Select.Option value="OP_CIBLE">OP Cible</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
