import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
  isAvailableMetric,
} from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { extractVariableFromMarkdocAst } from "../../email-widgets/text/domain";
import { type BaseConfig } from "../domain";
import { convertKeyToMarkdocVariable } from "../markdown/domain";
import type { IFilterEditorValue } from "../related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../related-lists/domain";

export interface IWidgetKPIConfig extends BaseConfig {
  columns: Array<string>;
  label?: string;
  secondary?: string;
  theme?: string;
  type?: "table" | "bar" | "progress" | "none";
  barConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    tooltip?: string;
    highlightLabel?: string;
    limit?: number;
  };
  tableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    highlightLabel?: string;
    limit?: number;
  };
}
type theme = "gray" | "blue" | "orange" | "yellow" | "green";

export type kpiTheme = {
  background: string;
  primary: string;
  secondary: string;
};

export const kpiThemes: {
  [key in theme]: kpiTheme;
} = {
  gray: {
    background: "#F7F7F8",
    primary: "#4C4C4C",
    secondary: "#AAAAAA",
  },
  blue: {
    background: "#E7F0FF",
    primary: "#184D80",
    secondary: "#85A1C1",
  },
  orange: {
    background: "#FFEFE6",
    primary: "#8D3300",
    secondary: "#BA988E",
  },
  yellow: {
    background: "#FFF5DA",
    primary: "#785800",
    secondary: "#B6A685",
  },
  green: {
    background: "#E6FAE8",
    primary: "#00640A",
    secondary: "#79ad75",
  },
};

const buildSparklineQuery = (options: {
  metrics: string[];
  dimensions: string[];
  recordFilter?: {
    recordForeignKey: string;
    recordId: string;
  };
  additionalFilters?: IFilterEditorValue;
  sortBy?: Array<[string, MeasureItemSortValue]>;
  limit?: number;
}): Query => {
  return buildQueryFromDimensionAndMetrics(
    options.dimensions,
    options.metrics,
    options.recordFilter,
    options.additionalFilters,
    options.sortBy,
    options.limit
  );
};

export const getForeignObject = (
  object: IObject,
  foreignObjectPropertyId: string
) => {
  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === foreignObjectPropertyId
  );
  if (!foreignProperty) return undefined;
  return foreignProperty.object;
};

export const getSparklineQuery = (options: {
  object: IObject;
  record: IRecord;
  conf: IWidgetKPIConfig;
}) => {
  const { object, record, conf } = options;

  const sparklineConfig =
    conf.type === "bar"
      ? conf.barConfig
      : conf.type === "table"
      ? conf.tableConfig
      : undefined;

  if (!sparklineConfig) {
    return undefined;
  }

  const foreignObject = getForeignObject(
    object,
    sparklineConfig.foreignObjectPropertyId
  );

  if (!foreignObject) {
    return undefined;
  }

  const property = foreignObject.properties.find(
    (p) => p.id === sparklineConfig?.foreignObjectPropertyId
  );

  if (!property) {
    return undefined;
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const metrics = sparklineConfig.metric;
  const dimensions = sparklineConfig?.groupBy;
  const recordFilter = {
    recordForeignKey: `${foreignAvailable.key}_raw`,
    recordId: record[`${object.table.cubeName}.id`] as string,
  };

  if (!metrics || !dimensions) {
    return;
  }

  const columns = getObjectColumns(foreignObject);

  const metric = columns
    .filter(isAvailableMetric)
    .find((m) => m.data.key === metrics[0]);

  if (!metric) {
    return undefined;
  }

  const getTooltipMetrics = (): string[] => {
    if (conf.barConfig?.tooltip) {
      const substitutionMetrics = columns.reduce<{ [key: string]: string }>(
        (acc, v) => {
          return {
            ...acc,
            [convertKeyToMarkdocVariable(v.data.key)]: v.data.key,
          };
        },
        {}
      );
      const variables = extractVariableFromMarkdocAst(conf.barConfig.tooltip);
      return variables.map((v) => substitutionMetrics[v]).filter((v) => !!v);
    }

    return [];
  };

  const getSortBy = (): Array<[string, MeasureItemSortValue]> => {
    if (conf.tableConfig?.sortBy) {
      return conf.tableConfig?.sortBy;
    }
    return metrics.map((m) => [m, "desc"]);
  };

  const getLimit = (): number => {
    if (conf.type === "bar") {
      return conf.barConfig?.limit ?? 4;
    } else if (conf.type === "table") {
      return conf.tableConfig?.limit ?? 10;
    } else {
      return 10;
    }
  };

  const finalMetrics = _.uniq([...metrics, ...getTooltipMetrics()]);

  return buildSparklineQuery({
    metrics: finalMetrics,
    dimensions: dimensions,
    additionalFilters: sparklineConfig.filters,
    recordFilter: recordFilter,
    sortBy: getSortBy(),
    limit: getLimit(),
  });
};
