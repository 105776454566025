import { type BaseConfig } from "../domain";
import type { IGeneratedTextDataSheet } from "../generated-text/domain";

export interface IWidgetMarkdownConfig extends BaseConfig {
  text?: string;
  boxed?: boolean;
  dataSheets?: IGeneratedTextDataSheet[];
}

export const convertKeyToMarkdocVariable = (key: string) => {
  return key.replaceAll(".", "_");
};

export const convertDataSheetNameToMarkdocVariable = (key: string) => {
  return `dataSheet_${key}`;
};
