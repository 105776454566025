import Markdoc from "@markdoc/markdoc";
import { Typography } from "antd/lib";
import _ from "lodash";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { getParsedDoc } from "../../../email-widgets/text/domain";
import { fetchDatasheetConcurrentlyReturnJSON } from "../../generated-text/renderer/api";
import type { IWidgetMarkdownConfig } from "../domain";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
} from "../domain";
import "./MarkdownWidget.scss";
import {
  additionalComponents,
  nodesConfig,
} from "./markdoc-react-component/config";

interface IMarkdownWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMarkdownConfig;
  edit?: boolean;
}

type Props = IMarkdownWidgetProps & InjectedOrgProps;

function MarkdownWidget(props: Props) {
  const { conf, record, user, object, org, edit, widget } = props;
  const source = conf?.text ? conf.text : "";
  const dataSheets =
    conf?.dataSheets && Array.isArray(conf.dataSheets) ? conf.dataSheets : [];

  const [dataSheetsData, setDatasheetsData] = React.useState<
    AsyncData<{ [key: string]: Array<IRecord> }>
  >({
    status: "initial",
  });

  const fetchData = async () => {
    try {
      setDatasheetsData({ status: "loading" });
      // fetch all datasheet & materialize them
      const dataSheetsResults = await fetchDatasheetConcurrentlyReturnJSON(
        dataSheets,
        org,
        record,
        object
      );

      const formattedDatasheetResults = Object.keys(dataSheetsResults).reduce(
        (acc, v) => {
          const dataSheetVar = convertDataSheetNameToMarkdocVariable(v);
          return {
            ...acc,
            [dataSheetVar]: dataSheetsResults[v].map((d) => {
              return Object.keys(d).reduce((a, b) => {
                return {
                  ...a,
                  [`${dataSheetVar}_${convertKeyToMarkdocVariable(b)}`]: d[b],
                };
              }, {});
            }),
          };
        },
        {}
      );

      setDatasheetsData({
        status: "success",
        data: formattedDatasheetResults,
      });
    } catch (err) {
      console.error(err);
      setDatasheetsData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (source && dataSheets && dataSheets.length > 0 && !edit) {
      fetchData();
    }
  }, [source, dataSheets]);

  if (dataSheets && dataSheets.length > 0 && !edit) {
    if (
      dataSheetsData.status === "initial" ||
      dataSheetsData.status === "loading"
    ) {
      return (
        <div style={{ height: 250 }}>
          <Loading />
        </div>
      );
    }
    if (dataSheetsData.status === "error") {
      return (
        <div style={{ height: 250 }}>
          <Feednack>There was an error fetching your datasheets</Feednack>
        </div>
      );
    }
  }

  if (!source) {
    return (
      <Typography.Text type="secondary">
        Please configure this widget
      </Typography.Text>
    );
  }

  const dataSheetFormattedData =
    dataSheetsData.status === "success" ? dataSheetsData.data : ({} as any);

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, dataSheetFormattedData);

  const columns = getObjectColumns(object);
  const content = getParsedDoc(
    source,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );

  const html = Markdoc.renderers.react(content, React, {
    ...additionalComponents,
  });

  // max-width: 612px;
  // margin-left: auto;

  return (
    <div
      className="markdown-widget"
      style={
        conf.boxed
          ? { maxWidth: 612, marginLeft: "auto", marginRight: "auto" }
          : undefined
      }
    >
      {html}
    </div>
  );
}

export default compose<Props, IMarkdownWidgetProps>(WithOrg)(MarkdownWidget);
