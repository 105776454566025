import { PushpinOutlined } from "@ant-design/icons";
import type { Filter } from "@cubejs-client/core";
import { Card, Divider, theme, Typography } from "antd";
import type { ColumnsSettings } from "../../../../../../../../components/ag-grid/object-table/domain";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { kpiThemes } from "../../kpi/domain";
import { ProgressSparkline } from "../../kpi/renderer/sparkline/progress/ProgressSparkline";
import type { IFilterEditorValue } from "../../related-lists/domain";
import RelatedListQuery from "../../related-lists/widget/RelatedListQuery";
import type { IWidgetInlineObjectiveConfig } from "../domain";

const { useToken } = theme;

interface IInlineObjectivesightWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetInlineObjectiveConfig;
  edit?: boolean;
}

type Props = IInlineObjectivesightWidgetProps;

export function InlineObjectiveWidget(props: Props) {
  const { record, conf, object } = props;

  const { token } = useToken();

  const getConfig = () => {
    if (conf.conf === "PDL_UF" || !conf.conf) {
      return {
        title: "Gagner de la PDL sur le rayon UF",
        secondary: "Objectif 1pt - Réalisé à Date +0.7pt - RAF 0.3pt",
        countLabel: "16 magasins",
        objectiveValue: 0.73,
        foreignObjectPropertyId: "797",
        options: {
          columns: [
            "Tab_8832.label",
            "Tab_8832.count",
            "Tab_8832.prop798",
            "Tab_8832.met41889",
            "Tab_8832.met41888",
            "Tab_8832.met41887",
          ],
          filters: {
            operator: "and",
            filters: [],
          },
          sortBy: [["Tab_8832.met41889", "asc"]],
          groupBy: [],
          hidePagination: true,
          pageSize: 16,
          showRowNumber: false,
          columnsSettings: {
            "Tab_8832.label": {
              label: "Magasin",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 250,
            },
            "Tab_8832.prop798": {
              initialSizeType: "fill",
            },
            "Tab_8832.met41889": {
              label: "Atteinte",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
            "Tab_8832.met41888": {
              label: "T0",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
            "Tab_8832.met41887": {
              label: "Courant",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
          },
        },
      };
    } else if (conf.conf === "DNR") {
      return {
        title: "Gagner de la DNR Inno Reno",
        secondary: "Objectif 543 codes - Réalisé à Date 396 - RAF 147",
        countLabel: "6 magasins",
        objectiveValue: 0.91,
        foreignObjectPropertyId: "797",
        options: {
          columns: [
            "Tab_8832.label",
            "Tab_8832.count",
            "Tab_8832.prop798",
            "Tab_8832.met41903",
            "Tab_8832.met41886",
          ],
          filters: {
            operator: "and",
            filters: [],
          },
          sortBy: [["Tab_8832.met41903", "desc"]],
          groupBy: [],
          hidePagination: true,
          pageSize: 6,
          showRowNumber: false,
          columnsSettings: {
            "Tab_8832.label": {
              label: "Magasin",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 250,
            },
            "Tab_8832.prop798": {
              initialSizeType: "fill",
            },
            "Tab_8832.met41903": {
              label: "RAF",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
            "Tab_8832.met41886": {
              label: "DNR Inno Reno",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
          },
        },
      };
    } else if (conf.conf === "OP_CIBLE") {
      return {
        title: "Transformation d’opérations ciblé",
        secondary: "Objectif 32 OP - Réalisé à Date 26 - RAF 6",
        countLabel: "9 magasins",
        objectiveValue: 0.8,
        foreignObjectPropertyId: "797",
        options: {
          columns: [
            "Tab_8832.label",
            "Tab_8832.count",
            "Tab_8832.prop798",
            "Tab_8832.met41884",
            "Tab_8832.met41885",
          ],
          filters: {
            operator: "and",
            filters: [],
          },
          sortBy: [["Tab_8832.met41884", "desc"]],
          groupBy: [],
          pageSize: 9,
          hidePagination: true,
          showRowNumber: false,
          columnsSettings: {
            "Tab_8832.label": {
              label: "Magasin",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 250,
            },
            "Tab_8832.prop798": {
              initialSizeType: "fill",
            },
            "Tab_8832.met41884": {
              label: "OP Cible - RAF",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
            "Tab_8832.met41885": {
              label: "OP Cible - Target",
              initialSizeType: "fixedWidth",
              initialSizeWidth: 62,
            },
          },
        },
      };
    }
    return {
      title: "Gagner de la PDL sur le rayon UF",
      secondary: "Objectif 1pt - Réalisé à Date +0.7pt - RAF 0.3pt",
      countLabel: "16 magasins",
      objectiveValue: 0.91,
      foreignObjectPropertyId: "797",
      options: {
        columns: [
          "Tab_8832.label",
          "Tab_8832.count",
          "Tab_8832.prop798",
          "Tab_8832.met41888",
          "Tab_8832.met41887",
          "Tab_8832.met41889",
        ],
        filters: {
          operator: "and",
          filters: [],
        },
        sortBy: [["Tab_8832.met41889", "asc"]],
        groupBy: [],
        hidePagination: true,
        showRowNumber: false,
        pageSize: 16,
        columnsSettings: {
          "Tab_8832.label": {
            label: "Magasin",
            initialSizeType: "fixedWidth",
            initialSizeWidth: 250,
          },
          "Tab_8832.prop798": {
            initialSizeType: "fill",
          },
          "Tab_8832.met41889": {
            label: "Atteinte",
            initialSizeType: "fixedWidth",
            initialSizeWidth: 62,
          },
          "Tab_8832.met41888": {
            label: "T0",
            initialSizeType: "fixedWidth",
            initialSizeWidth: 62,
          },
          "Tab_8832.met41887": {
            label: "Courant",
            initialSizeType: "fixedWidth",
            initialSizeWidth: 62,
          },
        },
      },
    };
  };

  const c = getConfig();

  if (!c.foreignObjectPropertyId) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Missing configuration</Feednack>
      </div>
    );
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === c.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </div>
    );
  }

  if (!c.options?.columns?.length) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Please add at least one column to display</Feednack>
      </div>
    );
  }

  const foreignObject = foreignProperty.object;

  const property = foreignObject.properties.find(
    (p) => p.id === c.foreignObjectPropertyId
  );

  if (!property) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Property must be defined</Feednack>
      </div>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const cubeName = object.table.cubeName;
  const recordFilters = [
    {
      member: `${foreignAvailable.key}_raw`,
      operator: "equals",
      values: [record[`${cubeName}.id`] as string],
    },
  ];
  const availableColumns = getObjectColumns(foreignObject);
  const columns = c.options.columns;
  const additionalFilters: IFilterEditorValue = c.options.filters as any;
  const sortBy: Array<any> = c.options?.sortBy ?? [];
  const groupBy = c.options?.groupBy ?? [];
  const hidePagination = c.options?.hidePagination;
  const showRowNumber = c.options?.showRowNumber;
  const columnsSettings = (c.options?.columnsSettings ?? {}) as ColumnsSettings;

  const filters = [
    {
      and: [...recordFilters],
    },
    {
      and: [
        additionalFilters
          ? { [additionalFilters.operator]: additionalFilters.filters }
          : { and: [] },
      ],
    },
  ] as Filter[];

  return (
    <Card size="small" styles={{ body: { padding: 0 } }}>
      <div
        style={{
          padding: `12px 24px`,
          display: "flex",
          alignItems: "center",
          gap: 12,
        }}
      >
        <div style={{ flex: 1 }}>
          <div>
            <Typography.Text strong>{c.title}</Typography.Text>

            <Typography.Text type="secondary">
              {" • "}
              {c.secondary}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">
              <PushpinOutlined /> {c.countLabel}
            </Typography.Text>
          </div>
        </div>
        <div>
          <Typography.Text style={{ fontSize: "1.2em" }} strong>
            {c.objectiveValue * 100}%
          </Typography.Text>
        </div>
        <div style={{ flex: `0 auto` }}>
          <ProgressSparkline
            size={48}
            rawValue={c.objectiveValue}
            theme={kpiThemes["gray"]}
          />
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <div style={{ margin: 12 }}>
        <RelatedListQuery
          object={foreignObject}
          availableColumns={availableColumns}
          pageSize={c.options.pageSize || 10}
          filters={filters}
          sortBy={sortBy}
          groupBy={groupBy}
          columns={columns}
          columnsSettings={columnsSettings}
          hidePagination={hidePagination}
          showRowNumber={showRowNumber}
        />
      </div>
    </Card>
  );
}
