import { markdown } from "@codemirror/lang-markdown";
import { syntaxHighlighting } from "@codemirror/language";
import { lintGutter } from "@codemirror/lint";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import type { ValidateError } from "@markdoc/markdoc";
import Markdoc from "@markdoc/markdoc";
import ReactCodeMirror, {
  type ReactCodeMirrorRef,
} from "@uiw/react-codemirror";
import cuid from "cuid";
import React, { useMemo } from "react";
import type { FormulaEditorColumnAutocompleteValue } from "../../../../../../../../components/formula/calculated-metric/CalculatedMetricAutocomplete";
import { CalculatedMetricFormulaEditorAutocomplete } from "../../../../../../../../components/formula/calculated-metric/CalculatedMetricAutocomplete";
import { CalculatedMetricFormulaEditorColumnsHighlight } from "../../../../../../../../components/formula/calculated-metric/CalculatedMetricColumnHighlight";
import { SQLEditorTheme } from "../../../../../../../workbench/workbench/dataset/tabs/sql/codemirrorPlugins/sqlTheme";
import { nodesConfig } from "../widget/markdoc-react-component/config";
import MarkdocExtention from "./MarkdocExtension";
import { markdocLinter } from "./MarkdocLinter";
import { markdocHighlightStyle } from "./markdocSyntaxHighlighting";

interface MarkdocEditorProps {
  columnsSuggestions: {
    key: string;
    label: string;
  }[];
  datasheetsSuggestions?: {
    key: string;
    label: string;
  }[];
  datasheetColumnsSuggestions?: {
    key: string;
    label: string;
    dataSheetName: string;
  }[];
  height?: number;
  value?: string;
  onChange?: (value: string) => void;
  avoidLineBreak?: boolean;
}

export const MarkdocEditor = (props: MarkdocEditorProps) => {
  const {
    columnsSuggestions,
    datasheetsSuggestions: datasheetsSuggestionsFromProps,
    onChange,
    height: heightFromProps,
    avoidLineBreak,
    datasheetColumnsSuggestions: datasheetColumnsSuggestionsFromProps,
  } = props;

  const datasheetsSuggestions = datasheetsSuggestionsFromProps
    ? datasheetsSuggestionsFromProps
    : [];

  const datasheetColumnsSuggestions = datasheetColumnsSuggestionsFromProps
    ? datasheetColumnsSuggestionsFromProps
    : [];

  const [id] = React.useState<string>(cuid());
  const ref = React.createRef<ReactCodeMirrorRef>();
  const [height, setHeight] = React.useState(heightFromProps ?? 0);
  const [errors, setErrors] = React.useState<ValidateError[]>([]);

  React.useEffect(() => {
    const doc = document.getElementById(id);
    if (doc && !heightFromProps) {
      const r = doc.getBoundingClientRect();
      if (height !== r.height) {
        setHeight(r.height);
      }
    }
  }, [height]);

  let value = "";
  if (typeof props.value === "string") {
    value = props.value;
  }

  React.useEffect(() => {
    const ast = Markdoc.parse(value);
    const errs = Markdoc.validate(ast, { ...nodesConfig });
    setErrors(errs);
  }, [value]);

  const availableColums: FormulaEditorColumnAutocompleteValue[] = [
    ...datasheetColumnsSuggestions.map<FormulaEditorColumnAutocompleteValue>(
      (cs) => ({
        key: cs.key,
        label: cs.label,
        section: cs.dataSheetName,
        highlightColor: "green",
      })
    ),
    ...columnsSuggestions.map<FormulaEditorColumnAutocompleteValue>((cs) => ({
      ...cs,
      highlightColor: "lightblue",
      section: datasheetsSuggestions.length > 0 ? "Column" : undefined,
    })),
    ...datasheetsSuggestions.map<FormulaEditorColumnAutocompleteValue>(
      (cs) => ({ ...cs, highlightColor: "purple", section: "Datasheet" })
    ),
  ];

  // This is a hack to make sure that the autocomplete does not resets
  // when the component rerenders
  const columnsSuggestionsStringified = JSON.stringify(availableColums);
  const extensions = useMemo(
    () => [
      CalculatedMetricFormulaEditorAutocomplete(availableColums),
      CalculatedMetricFormulaEditorColumnsHighlight(availableColums),
      SQLEditorTheme,
      lintGutter(),
      EditorView.lineWrapping,
      syntaxHighlighting(markdocHighlightStyle),
      markdown({
        extensions: [MarkdocExtention],
      }),
      markdocLinter(errors),
      ...(avoidLineBreak
        ? [
            EditorState.transactionFilter.of((tr) =>
              tr.newDoc.lines > 1 ? [] : tr
            ),
          ]
        : []),
    ],
    [columnsSuggestionsStringified, errors, avoidLineBreak]
  );

  return (
    <div id={id} style={{ height: "100%" }}>
      <ReactCodeMirror
        ref={ref}
        basicSetup={{
          foldGutter: false,
          lineNumbers: true,
          highlightActiveLine: true,
        }}
        extensions={extensions}
        height={`${height}px`}
        style={{
          border: "1px solid #D9D9D9",
          borderRadius: 6,
        }}
        value={value}
        onChange={(v) => {
          if (onChange) {
            onChange(v);
          }
        }}
      />
    </div>
  );
};
