import { Divider } from "./components/Divider";
import { Group } from "./components/Group";
import { Heading } from "./components/Heading";
import { List } from "./components/List";
import { Logo } from "./components/Logo";
import { Paragraph } from "./components/Paragraph";
import { RCA } from "./components/RCA";
import { Tag } from "./components/Tag";
import { TextFormatting } from "./components/TextFormatting";
import { Toggle } from "./components/Toggle";
import { group } from "./schema/group.markdoc";
import { heading } from "./schema/heading.markdoc";
import { hr } from "./schema/hr.markdoc";
import { list } from "./schema/list.makdoc";
import { logo } from "./schema/logo.markdoc";
import { paragraph } from "./schema/paragraph.markdoc";
import { rca } from "./schema/rca.markdoc";
import { tag } from "./schema/tag.markdoc";
import { textFormatting } from "./schema/text-formatting";
import { toggle } from "./schema/toggle.markdoc";

export const nodesConfig = {
  nodes: {
    heading,
    paragraph,
    list,
    hr,
  },
  tags: {
    toggle,
    rca,
    "text-formatting": textFormatting,
    group: group,
    tag,
    logo,
  },
};

export const additionalComponents = {
  components: {
    Heading: Heading,
    Paragraph: Paragraph,
    List: List,
    Divider: Divider,
    RCA: RCA,
    Toggle: Toggle,
    TextFormatting: TextFormatting,
    Group: Group,
    Tag: Tag,
    Logo,
  },
};
